/* eslint-disable no-undef */
import Rails from '@rails/ujs';
import $ from 'jquery';
import Inputmask from 'inputmask';
import 'bootstrap/dist/js/bootstrap';
import 'chartkick/chart.js';

// JS
import '../react_rails';
import '../shopify_app';

// Styles
import 'flatpickr/dist/flatpickr.css';
import './styles.scss';

/* Start Rails UJS */
Rails.start();

/* Import All Images */
require.context('../assets/images', true);

$(document).ready(() => {
  const phone = new Inputmask('(999) 999-9999');
  phone.mask(document.querySelectorAll('.phone'));
});
