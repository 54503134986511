document.addEventListener('DOMContentLoaded', () => {
  const shopifyInit = document.getElementById('shopify-app-init');
  if (shopifyInit) {
    const data = document.getElementById('shopify-app-init').dataset;
    const AppBridge = window['app-bridge'];
    const createApp = AppBridge.default;

    (window as any).app = createApp({
      apiKey: data.apiKey,
      shopOrigin: data.shopOrigin,
      forceRedirect: data.forceRedirect === 'true',
    });

    const { actions } = AppBridge;
    const { History, TitleBar } = actions;

    TitleBar.create((window as any).app, {
      title: data.page,
    });

    const history = History.create((window as any).app);
    history.dispatch(History.Action.PUSH, window.location.pathname);
  }
});
