import ReactOnRails from 'react-on-rails';

import App from 'public/App';
import ZendeskApp from 'public/ZendeskApp';
import ReplenishmentApp from 'public/ReplenishmentApp';
import Chat from 'public/components/Chat';

ReactOnRails.register({
  App,
  ZendeskApp,
  ReplenishmentApp,
  'Components::Chat::Chat': Chat,
});
